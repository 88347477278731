import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentDecoderService {
    private decodedValues: Map<string, string> = new Map();

    constructor() {
        this.initializeDecodedValues();
    }

    private initializeDecodedValues(): void {
        Object.keys(environment).forEach(key => {
            const value = environment[key];
            if (typeof value === 'string' && key !== 'production') {
                try {
                    this.decodedValues.set(key, atob(value));
                } catch {
                    this.decodedValues.set(key, value);
                }
            }
        });
    }

    getDecodedValue(key: keyof typeof environment): string {
        if (key === 'production' || typeof environment[key] !== 'string') {
            return environment[key] as string;
        }
        return this.decodedValues.get(key as string) || environment[key] as string;
    }

    get configToken(): string {
        return this.getDecodedValue('configToken');
    }
}